import * as Sentry from "@sentry/browser";

window.addEventListener("load", () => {
    const sentryEnabledEnvironments = ['sandbox', 'production'];
    const environmentName = sessionStorage.getItem('environment') ?? '';
    const sentryDSN = sessionStorage.getItem('sentryDSN') ?? '';

    if (sentryDSN !== '' && sentryEnabledEnvironments.includes(environmentName)) {
        Sentry.init({
            dsn: sentryDSN,
            environment: environmentName,
        });
    }
})
